/* General document */

* {
  box-sizing: border-box;
}

html {
  height: 100vh;
  overflow-x: hidden;
}

body {
  height: 100%;
  font-family: Inter, Arial, sans-serif;
  color: #192343;
  background-color: #fffdfd;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow-x: hidden;
}

h1,
h2 {
  font-family: Poppins, Calibri, sans-serif;
  font-weight: bold;
  color: #192343;
}

h2 {
  font-size: 1.5em;
}

.subhead {
  font-size: 1.2em;
}

.suba {
  font-size: 0.8em;
}
.subb {
  font-size: 1.1em;
}
.sub {
  font-size: 0.7em;
  font-weight: normal;
}

p,
li {
  font-size: 1.15em;
}

.editor-element-container {
  padding: 0rem;
  border: 1px solid #DEE2E6;
  border-radius: 8px;
  /* box-shadow: 0 5px 0 #C2DBFE; */

}

.editor-element-container.focused {
  border-color: #86B7FE;
  /* box-shadow: 0 5px 6 hex(#C2DBFE) */
  box-shadow: 4px 4px 0px rgba(194, 219, 254, 1.0),
              -4px -4px 0px rgba(194, 219, 254, 1.0),
              -4px 4px 0px rgba(194, 219, 254, 1.0),
              4px -4px 0px rgba(194, 219, 254, 1.0);
  transition: 150ms;
}

.editor-input-container {
  padding: 0rem 0.5rem;
}

.editor-toolbar-container {
  border: 1px;
  background-color:#EBE9E9;
  box-shadow: 0 1px 1px  rgba(0, 0, 0, 0.23);
  border-radius: 7px 7px 0 0;
}

/* Bootstrap overrides */

.bg-blue {
  background-color: #192343;
  color: #fffdfd;
}

.bg-gray {
  background-color: #fffdfd;
  color: #192343;
}

.bg-yellow {
  border: 1px solid #474540;
  border-radius: 5px;
  background-color: #FFC639;
  color: #192343;
}

.btn {
  border: 1px solid #474540;
  border-radius: 5px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.16), 0 1px 3px rgba(0, 0, 0, 0.23);
}

.btn:hover,
.btn:focus {
  border: 1px solid #474540;
  color: #192343;
}

.btn-accent {
  /* background-color: #ffa839; */
  background-color: #FFC639;
  color: #192343;

}

.btn-accent:hover,
.btn-accent:focus,
.btn-accent:active {
  background-color: #E8B331;
  /* background-color: #ffc27a; */
}

main .row {
  margin-bottom: 2rem;
}

main .row > div {
  margin-bottom: 1rem;
}

.sep {
  border-right: 0.15rem solid #474540;
}

.text-blue {
  color: #283254;
  font-weight: bold;
}

/* Nav bar */

nav {
  background-color: #fffdfd;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.16), 0px 1px 2px rgba(0, 0, 0, 0.23);
}

.navbar-brand h1 {
  font-size: 2rem;
}

/* Links */

a {
  text-decoration: none;
  color: inherit;
}

a:hover {
  color: inherit;
}

a > cite {
  text-decoration: underline; 
}

.a {
  font-style:  italic;
}

.a:hover {
  text-decoration: underline;
  color: #474540;
}


/* Icons */

.material-icons {
  position: relative;
  top: 5px;
}

.main-icon {
  font-size: 1em;
}

svg {
  height: 2em;
}

.linkedin {
  fill: #0e76a8;
}

.x {
  fill: black;
}

/* Forms */
textarea {
  width: 100%;
  /* white-space: pre; */
}

/* Images used */

img {
  max-width: 100%;
  height: auto;
  object-fit: cover;
  background-size: cover
}

.circle {
  border-radius: 50%;
  width: 70%;
  height: auto;
}

.round {
  border-radius: 15%;
}

/* Footer */

footer {
  position: relative;
  bottom: 0;
  left: 0;
  padding: 1rem;
}

.md-appear {
  display: none;
}

.sm-appear {
  display: flex;  
}


/* Media queries */
@media (min-width: 768px) {
  .md-appear {
    display: flex;
  }

  .sm-appear {
    display: none;
  }
}

@media (min-width: 992px) {
  .sept {
    border-right: 2px solid #474540;
  }
}

@media (min-width: 1200px) {
  h1 {
    font-size: 2.7em;
  }

  h2 {
    font-size: 2em;
  }
}


/* ------------- */
/* .App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
} */